import React from "react";

const TopLogos = () => {
  return (
    <div className="top-logos">
      <div className="container">
        <div className="top-logo-icon align-items-center">
          {/* <div>
            <a
              href="https://european-union.europa.eu/index_en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img loading="lazy" src="img/top-logo6.png" alt="Logo 6" />
            </a>
          </div> */}
          {/* <div>
            <a
              href="https://www.article19.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img loading="lazy" src="img/top-logo2.png" alt="Logo 2" />
            </a>
          </div> */}
          <div>
            <a
              // href="https://fedonepal.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img loading="lazy" src="img/top-logo3.png" alt="Logo 3" />
            </a>
          </div>
          <div>
            <a
              // href="https://www.facebook.com/pynaorganization/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img loading="lazy" src="img/top-logo4.png" alt="Logo 4" />
            </a>
          </div>
          <div>
            <a
              // href="https://www.tyithailand.or.th/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img loading="lazy" src="img/top-logo5.png" alt="Logo 5" />
            </a>
          </div>
          <div>
            <a
              // href="https://plan-international.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                loading="lazy"
                src="img/top-logo6.png"
                alt="Logo 1"
                // style={{ width: "8rem", aspectRatio: "16/9" }}
              />
            </a>
          </div>
          <div>
            <a
              // href="https://plan-international.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                loading="lazy"
                src="img/top-logo7.png"
                alt="Logo 1"
                // style={{ width: "5rem" }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopLogos;
