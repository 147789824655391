import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../context/LanguageContext";
import { usePreFetchData } from "../context/PreFetchDataContext";

const Footer = () => {
  const { language } = useLanguage();
  const [navbar, setNavbar] = useState([]);
  const { navData, theme } = usePreFetchData();

  useEffect(() => {
    setNavbar(navData[language]);
  }, [navData, language]);

  const handleDownload = (e) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.href = "/docs/OVOCHub_User_Guide.pdf";
    link.download = "OVOCHub_User_Guide.pdf";
    link.click();
  };

  return (
    <>
      <footer
        className="footer"
        id="footer-cont"
        style={{
          background: theme[language]?.general,
        }}
      >
        <div className="container">
          <div className="web-security">
            <p>
              <Link to="/privacy">
                {" "}
                <marquee behavior="scroll" direction="left">
                  Website Security and Data Protection
                </marquee>
              </Link>
            </p>
          </div>
          <div className="row">
            <div
              className="col-sm-6 col-xxl-6 col-xl-6 col-lg-6 col-md-6 f-logos"
              id="logos-ff"
            >
              {/* <div className="d-flex">
                <img
                  loading="lazy"
                  src="img/eu-logo-footer.png"
                  alt="EU Logo"
                />
              </div> */}
              <div className="d-flex foter-content">
                An earlier phase of this project was funded by the European
                Union. Its contents are the sole responsibility of OVOC
                Consortium and do not necessarily reflect the views of the
                European Union.
              </div>
            </div>
            <div className="col-sm-3 col-xxl-3 col-xl-3 col-lg-3 col-md-3">
              <h4>Quick Navigation</h4>
              <ul className="chat-board">
                <li>
                  <Link translate="no" to="/">
                    {navbar ? navbar[0]?.title : "Home"}
                  </Link>
                </li>
                <li>
                  <Link translate="no" to="/about">
                    {navbar ? navbar[1]?.title : "About Us"}
                  </Link>
                </li>
                <li>
                  <Link translate="no" to="/blogs-articles">
                    {navbar ? navbar[5]?.child[1]?.title : "Blogs & Articles"}
                  </Link>
                </li>
                <li>
                  <Link translate="no" to="/campaigns">
                    {navbar ? navbar[4]?.title : "Campaigns"}
                  </Link>
                </li>
                <li>
                  <Link translate="no" to="/chatboard">
                    {navbar ? navbar[3]?.child[2]?.title : "Chat board"}
                  </Link>
                </li>
                <li>
                  <Link translate="no" to="/events">
                    {navbar ? navbar[3]?.child[0]?.title : "Events"}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-3 col-xxl-3 col-xl-3 col-lg-3 col-md-3 links-cont">
              <ul>
                <li>
                  <Link translate="no" to="/contact">
                    {navbar ? navbar[6]?.title : "Contact Us"}
                  </Link>
                </li>
                <li>
                  <Link translate="no" to="/link-to-organizations">
                    Links to related organizations
                  </Link>
                </li>
                <li>
                  <Link translate="no" to="/terms">
                    {navbar ? navbar[9]?.title : "Terms & Conditions"}
                  </Link>
                </li>
                <li>
                  <Link translate="no" to="/privacy">
                    {navbar ? navbar[10]?.title : "Privacy Policy"}
                  </Link>
                </li>
                <li>
                  <Link translate="no" to="/faq">
                    {navbar ? navbar[11]?.title : "FAQ"}
                  </Link>
                </li>
                <li>
                  <Link onClick={handleDownload}>{"Download User Guide"}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div
              className="col-sm-8 col-xxl-8 col-xl-8 col-lg-8 col-md-6 coryrights-link"
              style={{
                display: "flex",
                alignItems: "center",
                justifycontent: "flex-start",
                flexWrap: "wrap",
                gap: "1em",
                paddingTop: "0px",
              }}
            >
              <a
                href="https://plan-international.org/asia-pacific/"
                target="_blank"
                translate="no"
                rel="noopener noreferrer"
              >
                {new Date().getFullYear()} Plan International all rights
                reserved
              </a>{" "}
              |{" "}
              <Link translate="no" to="/copyright">
                Copyright
              </Link>
            </div>
            <div className="col-sm-4 col-xxl-4 col-xl-4 col-lg-4 col-md-6 text-end footer-socials">
              <a
                href="https://www.facebook.com/PlaninAsiaPacific"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://twitter.com/planasiapacific"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/planasiapacific"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.youtube.com/user/planinternationaltv"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
